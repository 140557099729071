import React from 'react'
import '../styles/global.css'
import ButtonTalk from './ButtonTalk'
import Navbar from './Navbar'

export default function SubHeader( {title} ) {
    return (
        <header className="subpage-header">
            <Navbar />
            <section className="banner">
                <h1>{ title }</h1>
                <ButtonTalk className="btn-white"/>
            </section>
    </header>
    )
}